const currentUser = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN_USER':
      return {
        ...state,
        currentUser: action.currentUser
      };
    case 'USER_BIRTHDAY':
      return {
        ...state,
        birthdaySeen: action.birthdaySeen
      };
    case 'TOGGLE_CONFERENCE':
      return {
        ...state,
        conference: action.conference
      };
    case 'IMAGE_PREVIEW':
      return {
        ...state,
        imagePreview: action.imagePreview
      };
    default:
      return state;
  }
}

export default currentUser;