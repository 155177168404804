import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, Modal, Nav, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import { Route, Switch } from 'react-router-dom';
import axiosRequest from '../../../util/helpers/axiosRequest';
import PromptDeleteModal from '../../modals/PromptDeleteModal/PromptDeleteModal';
import { connect } from 'react-redux';
import { toggleConference } from '../../../actions';
import textColorPicker from '../../../util/helpers/textColorPicker';

import Discussion from './parts/Discussion';
import Students from './parts/Students';
import StudentsFaculty from '../Students/Students';
import Attendance from '../Attendance/Attendance';
import Schedule from '../Schedule/Schedule';
import Grades from '../Grades/Grades';
import Materials from '../Materials/Materials';
import Upcoming from '../Upcoming/Upcoming';
import Badges from './parts/Badges';
import NameLink from '../../common/NameLink/NameLink';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

const menu = [
  {
    title: 'Discussions',
    key: 'discussions'
  },
  {
    title: 'Materials',
    key: 'materials'
  },
  {
    title: 'Students',
    key: 'students'
  },
  {
    title: 'Schedule',
    key: 'schedule'
  },
  {
    title: 'Attendance',
    key: 'attendance'
  },
  {
    title: 'Grades',
    key: 'grades'
  },
  {
    title: 'Badges',
    key: 'badges'
  }
];

class Classroom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showUpcoming: false,
      isLoading: true,
      errorMessage: '',
      classInfo: null,
      meetingLinkModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        url: '',
        description: ''
      },
      deleteLinkModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      },
      conferenceModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      }
    };
  }
  componentDidMount() {
    const { classID, menu } = this.props.match.params;
    const { currentUser } = this.props;

    this.setState({
      ...this.state,
      showUpcoming: menu === 'discussions' || (currentUser.account_type !== 2 && this.props.match.params.menu === 'students')
    }, () => {
      axiosRequest('get', `faculty/class/${classID}`, null, ({ data: { data } }) => {
        this.setState({
          classInfo: data,
          isLoading: false,
          errorMessage: ''
        });
      }, (error) => {
        this.setState({
          classInfo: null,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;

    if (prevProps.match.params.menu !== this.props.match.params.menu) {
      this.setState({
        ...this.state,
        showUpcoming: this.props.match.params.menu === 'discussions' || (currentUser.account_type !== 2 && this.props.match.params.menu === 'students')
      });
    }
  }
  showMeetingLinkModal = () => {
    const { classInfo } = this.state;

    const inputs = {
      url: '',
      description: ''
    };

    if (classInfo.meeting_link) {
      if (classInfo.meeting_link.url) {
        inputs.url = classInfo.meeting_link.url;
      }

      if (classInfo.meeting_link.description) {
        inputs.description = classInfo.meeting_link.description;
      }
    }

    this.setState({
      ...this.state,
      meetingLinkModal: {
        ...inputs,
        show: true,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideMeetingLinkModal = () => {
    this.setState({
      ...this.state,
      meetingLinkModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        url: '',
        description: ''
      }
    });
  }
  showDeleteLinkModal = () => {
    this.setState({
      ...this.state,
      deleteLinkModal: {
        show: true,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteLinkModal = () => {
    this.setState({
      ...this.state,
      deleteLinkModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleCollapseMenu = event => {
    event.preventDefault();
    this.setState({
      ...this.state,
      showMenu: !this.state.showMenu
    });
  }
  handleShowUpcoming = event => {
    event.preventDefault();
    this.setState({
      ...this.state,
      showUpcoming: !this.state.showUpcoming
    });
  }
  handleMenuSelect = key => {
    this.props.history.push(key);
  }
  handleMeetingLinkInputChange = event => {
    this.setState({
      ...this.state,
      meetingLinkModal: {
        ...this.state.meetingLinkModal,
        [event.target.name]: event.target.value
      }
    });
  }
  handleUpdateMeetingLink = event => {
    event.preventDefault();
    
    this.setState({
      ...this.state,
      meetingLinkModal: {
        ...this.state.meetingLinkModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { classID } = this.props.match.params;
      const { url, description } = this.state.meetingLinkModal;
      
      axiosRequest('patch', `faculty/class/${classID}/meeting-link`, {
        url,
        description
      }, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          classInfo: {
            ...this.state.classInfo,
            meeting_link: data
          }
        }, this.hideMeetingLinkModal);
      }, error => {
        this.setState({
          ...this.state,
          meetingLinkModal: {
            ...this.state.meetingLinkModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleMeetingLink = (event, meetingLink) => {
    event.preventDefault();

    const { classID } = this.props.match.params;
    axiosRequest('get', `faculty/class/${classID}/meeting-link/${meetingLink.id}`, null, ({ data: { data } }) => {
      window.open(data, '_blank');
    }, error => {}, this.props.history);
  }
  handleDeleteLink = () => {
    const { classID } = this.props.match.params;
    const { classInfo } = this.state;

    axiosRequest('delete', `faculty/class/${classID}/meeting-link/${classInfo.meeting_link.id}`, null, ({ data: { message } }) => {
      this.setState({
        ...this.state,
        deleteLinkModal: {
          show: false,
          isLoading: false,
          errorMessage: ''
        },
        classInfo: {
          ...this.state.classInfo,
          meeting_link: null
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        deleteLinkModal: {
          ...this.state.deleteLinkModal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    });
  }
  handleMessage = () => {
    const { classInfo } = this.state;

    this.props.history.push('/chat', { newMessage: true, to: classInfo, type: 'classroom' });
  }
  hideConferenceModal = () => {
    this.setState({
      ...this.state,
      conferenceModal: {
        ...this.state.conferenceModal,
        show: false,
        errorMessage: ''
      }
    });
  }
  handleEnterClassroom = () => {
    const { classID } = this.props.match.params;

    this.setState({
      ...this.state,
      conferenceModal: {
        show: true,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('get', `class/${classID}/conference`, null, ({ data: { data } }) => {
        this.props.toggleConference(data);
        this.setState({
          ...this.state,
          conferenceModal: {
            ...this.state.conferenceModal,
            isLoading: false,
            errorMessage: '',
            show: false
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          conferenceModal: {
            ...this.state.conferenceModal,
            isLoading: false,
            errorMessage: error?.response?.data?.message ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    });
  }
  renderMeetingLink = meetingLink => {
    const { meetingLinkModal, deleteLinkModal } = this.state;
  
    return (
      <>
        <div className='flex-fill'></div>
        <div>
          <div className='d-flex align-items-center'>
            <div>
              {
                meetingLink ? (
                  <div className='d-flex align-items-center'>
                    {
                      meetingLink.description && (
                        <div className='mr-2'>
                          <OverlayTrigger
                            overlay={
                              <Tooltip>
                                <div style={{ whiteSpace: 'pre-line' }}>
                                  {meetingLink.description}
                                </div>
                              </Tooltip>
                            }
                            trigger={['hover', 'focus']}>
                            <FontAwesomeIcon icon='info-circle' className='info-icon text-info' />
                          </OverlayTrigger>
                        </div>
                      )
                    }
                    <div>
                      <span className='btn btn-link pl-0' onClick={e => this.handleMeetingLink(e, meetingLink)}>
                        <FontAwesomeIcon icon='external-link-alt' /> Meeting Link
                      </span>
                    </div>
                  </div>
                ) : (
                  <span className='text-muted font-italic'>
                    Meeting link is not set
                  </span>
                )
              }
            </div>
            <div className='ml-2'>
              <Button className='mr-2' variant='info' size='sm' title='Update meeting link' onClick={this.showMeetingLinkModal}>
                <FontAwesomeIcon icon='pencil-alt' />
                <span className='d-none d-md-inline-block ml-2'>Update meeting link</span>
              </Button>
              {
                meetingLink && (
                  <>
                    <Button className='mr-2' variant='danger' size='sm' title='Delete meeting link' onClick={this.showDeleteLinkModal}>
                      <FontAwesomeIcon icon='trash-alt' />
                      <span className='d-none d-md-inline-block ml-2'>Delete meeting link</span>
                    </Button>
                    <PromptDeleteModal
                      {...deleteLinkModal}
                      title='Delete meeting link'
                      onHide={this.hideDeleteLinkModal}
                      onDelete={this.handleDeleteLink}>
                      <Card.Subtitle>Are you sure you want to delete the meeting link?</Card.Subtitle>
                      <Alert variant='light'>
                        {meetingLink.url}
                      </Alert>
                    </PromptDeleteModal>
                  </>
                )
              }
            </div>
          </div>
          
          <Modal show={meetingLinkModal.show} onHide={this.hideMeetingLinkModal} backdrop='static'>
            <Modal.Header closeButton>
              <Modal.Title>Update Meeting Link</Modal.Title>
            </Modal.Header>
            <Form onSubmit={this.handleUpdateMeetingLink}>
              <Modal.Body>
                {
                  meetingLinkModal.errorMessage && (
                    <Alert variant='danger'>
                      {meetingLinkModal.errorMessage}
                    </Alert>
                  )
                }
                <Form.Group>
                  <Form.Label>URL</Form.Label>
                  <Form.Control type='url' name='url' value={meetingLinkModal.url} onChange={this.handleMeetingLinkInputChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description <span className='text-muted font-italic'>(Optional)</span></Form.Label>
                  <Form.Control as='textarea' name='description' value={meetingLinkModal.description} onChange={this.handleMeetingLinkInputChange} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button className='mr-2' variant='danger' onClick={this.hideMeetingLinkModal} disabled={meetingLinkModal.isLoading}>
                  Cancel
                </Button>
                <Button type='submit' variant='info' disabled={meetingLinkModal.isLoading}>
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
        {this.renderConference()}
      </>
    );
  }
  renderConference = () => {
    const { conferenceModal } = this.state;

    return (
      <>
        <div className='flex-fill'></div>
        <div>
          <Button variant='green' onClick={this.handleEnterClassroom} disabled={conferenceModal.isLoading}>
            <FontAwesomeIcon icon='sign-in-alt' /> Enter classroom
          </Button>
          <Modal show={conferenceModal.show} onHide={this.hideConferenceModal} backdrop='static'>
            {
              !conferenceModal.isLoading && (
                <Modal.Header closeButton>
                  <Modal.Title>Enter Classroom</Modal.Title>
                </Modal.Header>
              )
            }
            <Modal.Body>
              {
                conferenceModal.isLoading ? (
                  <LoadingIcon />
                ) : conferenceModal.errorMessage ? (
                  <Alert variant='danger'>
                    {conferenceModal.errorMessage}
                  </Alert>
                ) : null
              }
            </Modal.Body>
            {
              !conferenceModal.isLoading && (
                <Modal.Footer>
                  <Button variant='light' onClick={this.hideConferenceModal}>
                    Close
                  </Button>
                </Modal.Footer>
              )
            }
          </Modal>
        </div>
      </>
    );
  }
  renderMenu = () => {
    const { params } = this.props.match;

    return menu.map((m, index) => (
      <Nav.Item key={index}>
        <Nav.Link
          eventKey={m.key}
          onSelect={this.handleMenuSelect}
          active={m.key === params.menu}>
          {m.title}
        </Nav.Link>
      </Nav.Item>
    ));
  }
  render() {
    const { showMenu, showUpcoming, isLoading, errorMessage, classInfo } = this.state;
    const { currentUser } = this.props;

    if (isLoading) {
      return (
        <Card className='mt-3'>
          <Card.Body>
            <LoadingIcon lg />
          </Card.Body>
        </Card>
      );
    }

    if (errorMessage) {
      return (
        <Card className='mt-3'>
          <Card.Body>
            <Alert variant='danger'>
              {errorMessage}
            </Alert>
          </Card.Body>
        </Card>
      );
    }

    return (
      <div className='classroom' style={{ backgroundImage: classInfo.class_course.school_class.year_level.school_level ? `linear-gradient(to left, ${classInfo.class_course.school_class.year_level.school_level.theme} 1%, #fff)` : 'linear-gradient(to left, rgba(1,169,9,.35) 1%, #fff)' }}>
      <Row className='mt-3 rounded-top border border-bottom-0 classroom-info text-primary' style={{ backgroundImage: classInfo.class_course.school_class.year_level.school_level ? `linear-gradient(to left, ${classInfo.class_course.school_class.year_level.school_level.theme} 1%, #fff)` : 'linear-gradient(to left, rgba(1,169,9,.35) 1%, #fff)' }} noGutters>
        <Col lg={12} className='px-3 pt-3'>
          <div className='d-flex'>
            <div className='flex-fill d-flex flex-column'>
              <h5 className='mb-1'>{`${classInfo.class_course.course.description} (${classInfo.class_course.course.code})`}</h5>
              <div>{`${classInfo.class_course.school_class.program.description} (${classInfo.class_course.school_class.program.code})`}</div>
              <div>{`${classInfo.class_course.school_class.year_level.description} (${classInfo.class_course.school_class.year_level.code})`}</div>
              <div>{`Section ${classInfo.class_course.school_class.section.code}`}</div>
              {/* {this.renderMeetingLink(classInfo.meeting_link)} */}
              {this.renderConference()}
            </div>
            <div className='d-flex flex-column align-items-end'>
              <div className='flex-fill'>
                {
                  currentUser.account_type === 2 && (
                    <div className='mb-2'>
                      <Button variant='primary' title='Group chat' onClick={this.handleMessage}>
                        <FontAwesomeIcon icon='envelope' /> <span className='ml-1 d-none d-md-inline-block'>Message</span>
                      </Button>
                    </div>
                  )
                }
              </div>
              <div>
                <div className='d-none d-lg-block'>
                  {
                    currentUser.account_type === 2 ? (
                      <NameLink
                        {...currentUser.profile}
                        imageAlign='right'
                        imageStyle={{ height: '7.5rem', width: '7.5rem' }}
                        nameStyle={{ fontSize: '1.5rem', alignSelf: 'end', fontWeight: 'bold', color: classInfo.class_course.school_class.year_level.school_level ? textColorPicker(classInfo.class_course.school_class.year_level.school_level.theme) : '#fff', textAlign: 'right' }}
                        disableLink
                        showPosition
                      />
                    ) : (
                      <NameLink
                        {...classInfo.profile}
                        imageAlign='right'
                        imageStyle={{ height: '7.5rem', width: '7.5rem' }}
                        nameStyle={{ fontSize: '1.5rem', alignSelf: 'end', fontWeight: 'bold', color: classInfo.class_course.school_class.year_level.school_level ? textColorPicker(classInfo.class_course.school_class.year_level.school_level.theme) : '#fff', textAlign: 'right' }}
                        disableLink
                        showPosition
                      />
                    )
                  }
                </div>
                <div className='d-lg-none'>
                  {
                    currentUser.account_type === 2 ? (
                      <NameLink
                        {...currentUser.profile}
                        imageAlign='right'
                        imageStyle={{ width: '4rem', height: '4rem' }}
                        hideName
                        disableLink
                        showPosition
                      />
                    ) : (
                      <NameLink
                        {...classInfo.profile}
                        imageAlign='right'
                        imageStyle={{ width: '4rem', height: '4rem' }}
                        hideName
                        disableLink
                        showPosition
                      />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='dropdown-divider'></div>
        </Col>
      </Row>
      <Row className='content mb-5' noGutters>

        <Col lg={{ span: 3, order: 1 }} className='content-sidebar collapsible'>
          <Nav className={`flex-column bg-white ${showMenu ? 'show-collapse' : ''}`}>
            <Nav.Item className='collapse-trigger'>
              <Nav.Link className='d-flex justify-content-between align-items-center' onClick={this.handleCollapseMenu}>
                <div>Menu</div>
                <FontAwesomeIcon icon={showMenu ? 'chevron-up' : 'chevron-down'} />
              </Nav.Link>
            </Nav.Item>
            { this.renderMenu() }
            <Nav.Item>
              <Nav.Link className='d-flex justify-content-between align-items-center show-upcoming-switch' onClick={this.handleShowUpcoming}>
                <Form.Check
                  type='switch'
                  id='showUpcoming'
                  label='Activities'
                  checked={showUpcoming}
                  readOnly
                />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>

        <Col xs={{ order: 3 }} className='content-data'>
          <Switch>
            <Route exact path={`/faculty/class/:classID/discussions`} component={Discussion} />
            <Route
              exact
              path={`/:userType/class/:classID/students`}
              component={
                props => currentUser.account_type === 2 ?
                  <StudentsFaculty {...props} classInfo={classInfo} /> :
                  <Students {...props} classInfo={classInfo} />
              } />
            <Route exact path={`/:userType/class/:classID/attendance`} component={(props) => <Attendance {...props} classInfo={classInfo} />} />
            <Route exact path={`/:userType/class/:classID/schedule`} component={Schedule} /> {/*TODO key of weekly and by date*/}
            <Route exact path={`/:userType/class/:classID/grades`} component={(props) => <Grades {...props} classInfo={classInfo} />} /> {/*TODO key of weekly and by date*/}
            <Route exact path={`/:userType/class/:classID/badges`} component={Badges} />
            <Route path={`/:userType/class/:classID/materials`} component={Materials} />
          </Switch>
        </Col>

        {
          showUpcoming && (
            <Col xs={{ span: 12, order: 2 }} lg={{ span: 3, order: 4 }} className='ml-lg-3 border upcoming-bordered-container'>
              <div>
                <div className='h6 px-2 pt-2'>Activities</div>
                <div className='dropdown-divider'></div>
                <Upcoming {...this.props} />
              </div>
            </Col>
          )
        }
      </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleConference: conference => dispatch(toggleConference(conference))
});

export default connect(mapStateToProps, mapDispatchToProps)(Classroom);