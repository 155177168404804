import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { toggleConference } from '../../../actions';
import './style.scss';
import NameLink from '../../common/NameLink/NameLink';
import axiosRequest from '../../../util/helpers/axiosRequest';
import moment from 'moment';
import ExportAttendance from '../../modals/ExportAttendance/ExportAttendance';
import Announcement from '../Announcement/Announcement';
import Badges from '../Badges/Badges';
import Events from '../Events/Events';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

class AdminSchoolClasses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: {
        data: []
      },
      isLoading: true,
      errorMessage: '',
      showTop: false,
      // isTopLoading: false,
      // topError: '',
      // top: null,
      showExportAttendance: false,
      classID: '',
      searchQuery: '',
      isNextPageLoading: false,
      nextPageError: '',
      conferenceModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      }
    }
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        ...this.state,
        ...this.props.location.state
      });
    } else {
      const { userType } = this.props.match.params;
      axiosRequest('get', `${userType}/classes`, null, ({ data: { data } }) => {
        // let classes = [...data.classes];
        // classes.sort((a, b) => {
        //   if (!a.schedule && !b.schedule) {
        //     return 0;
        //   } else if (!a.schedule && b.schedule) {
        //     return 1;
        //   } else if (a.schedule && !b.schedule) {
        //     return -1;
        //   }

        //   if (!a.schedule.start_time && !b.schedule.start_time) {
        //     return 0;
        //   } else if (!a.schedule.start_time && b.schedule.start_time) {
        //     return 1;
        //   } else if (a.schedule.start_time && !b.schedule.start_time) {
        //     return -1;
        //   }

        //   let momentA = moment(a.schedule.start_time, 'HH:mm');
        //   let momentB = moment(b.schedule.start_time, 'HH:mm');

        //   if (momentA.isSame(momentB)) {
        //     return 0;
        //   } else if (momentA.isAfter(momentB)) {
        //     return 1;
        //   } else if (momentA.isBefore(momentB)) {
        //     return -1;
        //   }

        //   return 0;
        // });
        this.setState({
          ...this.state,
          ...data,
          // classes,
          isLoading: false,
          errorMessage: ''
        }, () => {
          this.props.history.replace(this.props.location.pathname, {...this.state});
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    }
  }
  handleNextPage = event => {
    event.preventDefault();
    
    if (!this.state.isNextPageLoading) {
      this.setState({
        ...this.state,
        isNextPageLoading: true
      }, () => {
        const { classes } = this.state;

        const path = classes.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
        
        axiosRequest('get', path, null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            classes: {
              ...data.classes,
              data: [
                ...this.state.classes.data,
                ...data.classes.data
              ]
            },
            isNextPageLoading: false,
            nextPageError: ''
          }, () => {
            this.props.history.replace(this.props.location.pathname, {...this.state});
          });
        }, (error) => {
          this.setState({
            ...this.state,
            isNextPageLoading: false,
            nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    }
  }
  handleClick = (e, id) => {
    e.preventDefault();

    const { history } = this.props;
    history.push(`${history.location.pathname}/class/${id}`);

    return false;
  }
  handleSelectSchoolClass = eventKey => {
    const { top } = this.state;

    if (top.badges[eventKey]) {
      this.setState({
        ...this.state,
        top: {
          ...top,
          school_class_id: eventKey
        }
      });
      return;
    }

    this.setState({
      ...this.state,
      top: {
        ...top,
        school_class_id: eventKey
      },
      isTopLoading: true,
      topError: ''
    }, () => {
      const { userType } = this.props.match.params;
      axiosRequest('get', `${userType}/badges/${eventKey}`, null, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          top: {
            ...this.state.top,
            badges: {
              ...this.state.top.badges,
              ...data.badges
            }
          },
          isTopLoading: false,
          topError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isTopLoading: false,
          topError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleShowExportAttendance = (event, classID) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      ...this.state,
      showExportAttendance: true,
      classID
    }, () => {
      this.props.history.replace(this.props.location.pathname, {...this.state});
    });
  }
  handleHideExportAttendance = () => {
    this.setState({
      ...this.state,
      showExportAttendance: false,
      classID: ''
    }, () => {
      this.props.history.replace(this.props.location.pathname, {...this.state});
    });
  }
  handleSearchInputChange = event => {
    this.setState({
      ...this.state,
      searchQuery: event.target.value
    }, () => {
      this.props.history.replace(this.props.location.pathname, {...this.state});
    });
  }
  handleMeetingLink = (event, meetingLink, classID) => {
    event.stopPropagation();
    event.preventDefault();

    const { userType } = this.props.match.params;
    axiosRequest('get', `${userType}/class/${classID}/meeting-link/${meetingLink.id}`, null, ({ data: { data } }) => {
      window.open(data, '_blank');
    }, error => {}, this.props.history);
  }
  hideConferenceModal = () => {
    this.setState({
      ...this.state,
      conferenceModal: {
        ...this.state.conferenceModal,
        show: false,
        errorMessage: ''
      }
    });
  }
  handleEnterClassroom = (event, classID) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      ...this.state,
      conferenceModal: {
        show: true,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('get', `school-admin/class/${classID}/conference`, null, ({ data: { data } }) => {
        this.props.toggleConference(data);
        this.setState({
          ...this.state,
          conferenceModal: {
            ...this.state.conferenceModal,
            isLoading: false,
            errorMessage: '',
            show: false
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          conferenceModal: {
            ...this.state.conferenceModal,
            isLoading: false,
            errorMessage: error?.response?.data?.message ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    });
  }
  renderTopBadge = badge => {
    if (!badge) {
      return null;
    }

    return (
      <div>
        <span>
          <FontAwesomeIcon icon='trophy' size='lg' />
        </span>
        <span className='pl-2'>
          x {badge}
        </span>
      </div>
    );
  }
  renderBadgeTable = top => {
    const { isTopLoading, topError } = this.state;

    if (isTopLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (topError) {
      return (
        <Alert variant='danger'>
          {topError}
        </Alert>
      );
    }

    if (!Array.isArray(top) || top.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <Table size='sm' responsive striped hover className='top-badge-table'>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th className='text-center'>
              <span className='fa-layers fa-fw my-1 mx-2' title='Login'>
                <FontAwesomeIcon icon='sign-in-alt' size='lg' />
                <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-7' className='text-green' />
              </span>
            </th>
            <th className='text-center'>
              <span className='fa-layers fa-fw my-1 mx-2' title='Submission'>
                <FontAwesomeIcon icon='paper-plane' size='lg' />
                <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-9' className='text-green' />
              </span>
            </th>
            <th className='text-center'>
              <span className='fa-layers fa-fw my-1 mx-2' title='Attendance'>
                <FontAwesomeIcon icon='calendar-alt' size='lg' />
                <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
              </span>
            </th>
            <th className='text-center'>
              <span className='fa-layers fa-fw my-1 mx-2' title='Participation'>
                <FontAwesomeIcon icon='comments' size='lg' />
                <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
              </span>
            </th>
            <th className='text-center'>
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {
            top.map((student, rankIndex) => (
              <tr key={student.profile.id} className={rankIndex === 0 ? 'h5' : rankIndex === 1 ? 'h6' : ''}>
                <th className='text-center align-middle'>
                  {rankIndex+1}
                </th>
                <th>
                  <NameLink {...student.profile} />
                </th>
                <td>{this.renderTopBadge(student.Login)}</td>
                <td>{this.renderTopBadge(student.Submission)}</td>
                <td>{this.renderTopBadge(student.Attendance)}</td>
                <td>{this.renderTopBadge(student.Participation)}</td>
                <td>{this.renderTopBadge(student.total)}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    );
  }
  renderSchedule = schedule => {
    if (!schedule) {
      return null;
    }

    if (schedule.start_time && schedule.end_time) {
      let momentStart = moment(schedule.start_time, 'HH:mm');
      let momentEnd = moment(schedule.end_time, 'HH:mm');
      return (
        <div className='d-flex align-items-center'>
          {
            moment().isBetween(momentStart, momentEnd) && (
              <div className='mr-2'>
                <FontAwesomeIcon icon='dot-circle' className='text-green' size='sm' />
              </div>
            )
          }
          <div className='font-italic'>
            <div>
              {`${momentStart.format('h:mm A')} - ${momentEnd.format('h:mm A')}`}
            </div>
            {
              schedule.type && (
                <div>
                  {schedule.type}
                </div>
              )
            }
          </div>
        </div>
      );
    }

    return (
      <div className='font-italic'>No schedule for today</div>
    );
  }
  renderClasses = () => {
    const { classes, searchQuery, isNextPageLoading, nextPageError } = this.state;
    const { userType } = this.props.match.params;

    if (classes.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }
    
    let filteredClasses = [...classes.data];

    if (searchQuery) {
      filteredClasses = [...classes.data].filter(cl => {
        return cl.profile.name.indexOf(searchQuery) > -1 ||
                cl.class_course.school_class.program.code.indexOf(searchQuery) > -1 ||
                cl.class_course.school_class.program.description.indexOf(searchQuery) > -1 ||
                cl.class_course.school_class.year_level.code.indexOf(searchQuery) > -1 ||
                cl.class_course.school_class.year_level.description.indexOf(searchQuery) > -1 ||
                cl.class_course.school_class.section.code.indexOf(searchQuery) > -1 ||
                cl.class_course.course.code.indexOf(searchQuery) > -1 ||
                cl.class_course.course.description.indexOf(searchQuery) > -1;
      });
    }

    return (
      <>
        <Row>
          <Col lg={6}>
            <div className='mb-3'>
              <Form.Control type='search' placeholder='Search classroom...' value={searchQuery} onChange={this.handleSearchInputChange} />
            </div>
          </Col>
        </Row>
        <Row className='my-n2'>
        {
          filteredClasses.length > 0 && filteredClasses.map(cl => (
            <Col key={cl.id} lg={4} className='my-2'>
              <a className='school-class-card-link' href={`${this.props.history.location.pathname}/class/${cl.id}`} onClick={(e) => this.handleClick(e, cl.id)}>
                <Card className='shadow-sm school-class-card text-primary'>
                  <Card.Body style={{ backgroundImage: cl.class_course.school_class.year_level.school_level ? `linear-gradient(135deg, #fff 25%, ${cl.class_course.school_class.year_level.school_level.theme})` : 'linear-gradient(135deg, #fff 25%, rgba(1,169,9,.35))' }}>
                    <div className='d-flex'>
                      <div className='flex-fill'>
                        <b>{cl.class_course.course.code}</b>
                        <div>{cl.class_course.course.description}</div>
                        <b>{`${cl.class_course.school_class.program.code} ${cl.class_course.school_class.year_level.code} - ${cl.class_course.school_class.section.code}`}</b>
                        <div>{cl.class_course.school_class.program.description}</div>
                        <div>{cl.class_course.school_class.year_level.description}</div>
                        {
                          cl.class_faculty_load.map(clf => (
                            <div key={clf.id}>
                              <b>{`${clf.school_class.program.code} ${clf.school_class.year_level.code} - ${clf.school_class.section.code}`}</b>
                              <div>{clf.school_class.program.description}</div>
                              <div>{clf.school_class.year_level.description}</div>
                            </div>
                          ))
                        }
                      </div>
                      {
                        userType === 'staff' && (
                          <div>
                            <Button variant='primary' size='sm' onClick={event => this.handleShowExportAttendance(event, cl.id)} title='Export attendance'>
                              <FontAwesomeIcon icon='file-export' />
                            </Button>
                          </div>
                        )
                      }
                    </div>
                    <div className='d-flex'>
                      <div className='flex-fill d-flex flex-column'>
                        <div className='flex-fill mt-3'>
                          {/* {
                            userType === 'school-admin' && (
                              <>
                                <Button variant='green' onClick={e => this.handleEnterClassroom(e, cl.id)} disabled={conferenceModal.isLoading} size='sm'>
                                  <FontAwesomeIcon icon='sign-in-alt' /> Enter classroom
                                </Button>
                                <Modal show={conferenceModal.show} onHide={this.hideConferenceModal} backdrop='static'>
                                  {
                                    !conferenceModal.isLoading && (
                                      <Modal.Header closeButton>
                                        <Modal.Title>Enter Classroom</Modal.Title>
                                      </Modal.Header>
                                    )
                                  }
                                  <Modal.Body>
                                    {
                                      conferenceModal.isLoading ? (
                                        <LoadingIcon />
                                      ) : conferenceModal.errorMessage ? (
                                        <Alert variant='danger'>
                                          {conferenceModal.errorMessage}
                                        </Alert>
                                      ) : null
                                    }
                                  </Modal.Body>
                                  {
                                    !conferenceModal.isLoading && (
                                      <Modal.Footer>
                                        <Button variant='light' onClick={this.hideConferenceModal}>
                                          Close
                                        </Button>
                                      </Modal.Footer>
                                    )
                                  }
                                </Modal>
                              </>
                            )
                          } */}
                          {/* {
                            (userType === 'school-admin' && cl.meeting_link) && (
                              <div>
                                <span className='btn btn-link pl-0' onClick={e => this.handleMeetingLink(e, cl.meeting_link, cl.id)}>
                                  <FontAwesomeIcon icon='external-link-alt' /> Meeting Link
                                </span>
                              </div>
                            )
                          } */}
                        </div>
                        {this.renderSchedule(cl.schedule)}
                      </div>
                      <div className='d-flex justify-content-end text-right align-items-end'>
                        <NameLink
                          {...cl.profile}
                          imageAlign='right'
                          nameStyle={{ alignSelf: 'end' }}
                          imageStyle={{ height: '5rem', width: '5rem' }}
                          hideName
                          disableLink
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          ))
        }
        {
          classes.next_page_url && (
            <Col lg={4} className='my-2'>
              <Card className={`h-100 shadow-sm show-more-class-card ${isNextPageLoading ? 'disabled' : ''}`} onClick={this.handleNextPage}>
                <Card.Body>
                  <div className='d-flex justify-content-center align-items-center h-100 flex-column'>
                    <div>
                      {
                        (!isNextPageLoading && searchQuery && filteredClasses.length === 0) && (
                          <Alert variant='light'>
                            No results found.
                          </Alert>
                        )
                      }
                    </div>
                    <div className='text-green h1'>
                      {
                        isNextPageLoading ? (
                          <LoadingIcon />
                        ) : (
                          <FontAwesomeIcon icon='arrow-circle-right' />
                        )
                      }
                    </div>
                    <div className='text-center'>
                      <div className='h5 text-green'>
                        Load more...
                      </div>
                      {
                        nextPageError && (
                          <Alert variant='danger'>
                            {nextPageError}
                          </Alert>
                        )
                      }
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )
        }
        </Row>
        {
          (filteredClasses.length === 0 && !classes.next_page_url) && (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          )
        }
      </>
    );
  }
  render() {
    const { isLoading, errorMessage, showTop, showExportAttendance, classID } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon className='mt-3' lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger' className='mt-3'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <Row>
          <Col lg={8}>
            <Announcement {...this.props} />
          </Col>
          <Col lg={4}>
            <Events {...this.props} />
          </Col>
        </Row>
        <Badges {...this.props} show={showTop} />
        <div className='my-3'>
          { this.renderClasses() }
          <ExportAttendance {...this.props} show={showExportAttendance} onHide={this.handleHideExportAttendance} classID={classID} />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  toggleConference: conference => dispatch(toggleConference(conference))
});

export default connect(null, mapDispatchToProps)(AdminSchoolClasses);