export const loginUser = user => ({
  type: 'LOGIN_USER',
  currentUser: user
});

export const logoutUser = () => ({
  type: 'LOGOUT_USER',
  currentUser: null
});

export const seenBirthday = () => ({
  type: 'USER_BIRTHDAY',
  birthdaySeen: true
});

export const toggleConference = src => ({
  type: 'TOGGLE_CONFERENCE',
  conference: {
    show: !!src,
    src
  }
});

export const showImagePreview = (images, activeIndex = 0, deletable = false, onDelete = () => {}) => ({
  type: 'IMAGE_PREVIEW',
  imagePreview: {
    images,
    activeIndex,
    deletable,
    onDelete
  }
});

export const hideImagePreview = () => ({
  type: 'IMAGE_PREVIEW',
  imagePreview: {
    images: [],
    activeIndex: 0,
    deletable: false,
    onDelete: () => {}
  }
});