import React, { Component } from 'react';
import { Alert, Nav, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

const accountTypeMap = {
  1: 'school-admin',
  2: 'faculty',
  3: 'staff',
  4: 'parent',
  5: 'student'
};

const learningPlatforms = {
  'Digital': (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <div className='font-weight-bold'>Learning Platform</div>
          <div>Digital</div>
        </Tooltip>
      }
      trigger={['hover', 'focus']}>
      <FontAwesomeIcon icon='laptop' className='fa-fw text-primary' />
    </OverlayTrigger>
  ),
  'Digital-Modular': (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <div className='font-weight-bold'>Learning Platform</div>
          <div>Digital-Modular</div>
        </Tooltip>
      }
      trigger={['hover', 'focus']}>
      <span className='fa-layers fa-fw text-primary'>
        <FontAwesomeIcon icon='laptop' transform='shrink-3 left-5 up-2' />
        <FontAwesomeIcon icon='book-open' transform='shrink-3 right-3 down-5' />
      </span>
    </OverlayTrigger>
  ),
  'Modular-Printed': (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <div className='font-weight-bold'>Learning Platform</div>
          <div>Modular-Printed</div>
        </Tooltip>
      }
      trigger={['hover', 'focus']}>
      <FontAwesomeIcon icon='book-open' className='fa-fw text-primary' />
    </OverlayTrigger>
  )
};

export default class TermGrades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      terms: [],
      selectedTerm: '',
      facultyLoads: []
    };
  }
  componentDidMount() {
    const { profile } = this.props;
    axiosRequest('get', `profile-menu/${profile.id}/grades`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false,
        selectedTerm: data.terms && data.terms.length > 0 ? data.terms[0].id : ''
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelectTerm = selectedTerm => {
    this.setState({
      ...this.state,
      selectedTerm
    });
  }
  renderGrade = facultyLoadID => {
    const { grades, selectedTerm } = this.state;

    let grade = grades.find(g => g.term_id === +selectedTerm && g.faculty_load_id === facultyLoadID);
    return grade ? grade.grade : '';
  }
  renderTable = () => {
    const { terms, selectedTerm, facultyLoads } = this.state;
    const { currentUser, profile } = this.props;

    
    let selectedTermData = terms.find(t => t.id === +selectedTerm);

    if (
      (profile.year_level && profile.year_level.school_level.term_grades) ||
      profile.student_class.school_class.year_level.school_level.term_grades
    ) {
      if (selectedTermData && !selectedTermData.enabled_grade) {
        return (
          <Alert variant='danger'>
            Grades for the term <b>{selectedTermData.title}</b> are not available for viewing.
          </Alert>
        );
      }
    }

    return (
      <>
        <Alert variant='warning'>
          The <b>official copy of grades</b> can be <b>requested</b> in the <b>registrar's office</b>.
        </Alert>
        <Table hover responsive>
          <thead>
            <tr>
              <th className='w-75'>Course</th>
              <th className='text-center'>Grade</th>
            </tr>
          </thead>
          <tbody>
            {
              facultyLoads.map(facultyLoad => (
                <tr key={facultyLoad.id}>
                  <td>
                    {
                      accountTypeMap[currentUser.account_type] ? (
                        <div>
                          <a href={`/${accountTypeMap[currentUser.account_type]}/class/${facultyLoad.id}`} className='btn-link'>
                            <span className='font-weight-bold'>
                              {facultyLoad.class_course.course.code}
                              <span className='ml-2'>
                                <FontAwesomeIcon icon='external-link-alt' />
                              </span>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div className='font-weight-bold'>
                          {facultyLoad.class_course.course.code}
                        </div>
                      )
                    }
                    <div>{facultyLoad.class_course.course.description}</div>
                    <div>{facultyLoad.profile.name}</div>
                    {
                      (facultyLoad.student_learning_platform && facultyLoad.student_learning_platform.platform) && (
                        <div>
                          <span className='font-italic'>Learning Platform:</span> {learningPlatforms[facultyLoad.student_learning_platform.platform]}
                        </div>
                      )
                    }
                  </td>
                  <th style={{ fontSize: '1.1rem' }} className='text-center align-middle'>
                    {this.renderGrade(facultyLoad.id)}
                  </th>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </>
    );
  }
  render() {
    const { isLoading, errorMessage, terms, selectedTerm, facultyLoads } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (terms.length === 0 || facultyLoads.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      )
    }


    return (
      <div>
        <div className='mb-3'>
          <Nav fill variant='pills' activeKey={selectedTerm} className='m-0' onSelect={this.handleSelectTerm}>
            {
              terms.map(term => (
                <Nav.Item key={term.id}>
                  <Nav.Link eventKey={term.id}>{term.title}</Nav.Link>
                </Nav.Item>
              ))
            }
          </Nav>
        </div>
        {this.renderTable()}
      </div>
    );
  }
}