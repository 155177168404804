import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { toggleConference } from '../../../actions';
import './style.scss';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

class Conference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      minimized: false,
      smallWindow: false
    };
  }
  componentDidMount() {
    if (this.props.conference?.show) {
      document.body.style.overflow = 'hidden';
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.minimized !== this.state.minimized || prevState.minimized !== this.state.smallWindow) {
      if (this.state.minimized || this.state.smallWindow) {
        document.body.style.overflow = 'unset';
      } else if (this.props.conference?.show) {
        document.body.style.overflow = 'hidden';
      }
    }

    if (prevProps.conference?.show !== this.props.conference?.show) {
      if (!this.props.conference?.show) {
        this.setState({
          ...this.state,
          isLoading: true,
          minimized: false,
          smallWindow: false
        });
        document.body.style.overflow = 'unset';
      } else {
        document.body.style.overflow = 'hidden';
      }
    }
  }
  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }
  handleLoad = e => {
    this.setState({
      ...this.state,
      isLoading: false
    });
  }
  toggleMinimize = () => {
    this.setState({
      ...this.state,
      minimized: !this.state.minimized,
      smallWindow: false
    });
  }
  toggleWindow = () => {
    this.setState({
      ...this.state,
      smallWindow: !this.state.smallWindow,
      minimized: false
    });
  }
  handleClose = e => {
    this.props.toggleConference(false);
  }
  render() {
    const { isLoading, smallWindow, minimized } = this.state;
    const { conference, currentUser } = this.props;
    // const { src } = this.props;
    
    if (!conference?.show) {
      return null;
    }

    if (!conference?.src) {
      return null;
    }

    return (
      <div className={smallWindow ? 'conference-container-small shadow-lg' : minimized ? 'conference-container-minimized' : 'conference-container'}>
        {
          isLoading && (
            <div className='conference-loading-container'>
              <LoadingIcon className='text-light' lg />
            </div>
          )
        }
        <div className='conference-control-buttons text-right py-1 px-2'>
          {
            (currentUser && currentUser.account_type === 2) && (
              <>
                <Button variant='outline-light' size='sm' onClick={this.toggleMinimize} title={minimized ? 'Maximize' : 'Minimize'}>
                  <FontAwesomeIcon icon='window-minimize' className='fa-fw' />
                </Button>
                <Button variant='outline-light' size='sm' className='ml-1' onClick={this.toggleWindow} title={smallWindow ? 'Large' : 'Small'}>
                  <FontAwesomeIcon icon='window-restore' className='fa-fw' />
                </Button>
              </>
            )
          }
          <Button variant='outline-light' size='sm' className='ml-1' onClick={this.handleClose}>
            <FontAwesomeIcon icon='times' className='fa-fw' />
          </Button>
        </div>
        <iframe
          title='Conference'
          src={conference.src}
          allow="camera;microphone;display-capture"
          className='conference-iframe'
          onLoad={this.handleLoad}
          allowFullScreen>
          Unsupported. Please update your browser.
        </iframe>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  conference: state.conference,
  currentUser: state.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleConference: conference => dispatch(toggleConference(conference))
});

export default connect(mapStateToProps, mapDispatchToProps)(Conference);